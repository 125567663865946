@font-face {
  font-family: myFont;
  src: url('./assets/font.ttf');
}

* {
  box-sizing: border-box;
  font-family: myFont !important;
}

html,
body{
  height: 100%;
}

body {
  background-color: #ECEFF1;
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.majorImg {
  width: 60%;
  margin: 2% auto;
  display: block;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
